import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import bgImage from "../assets/bgImage.png";
import loginIcon from "../assets/loginLogo.png";

const AuthFlowWrapper = ({ children }) => {
  return (
    <Flex
      h={["80vh", "80vh", "100vh"]}
      width="100%"
      flexDir={"column"}
      bgImage={bgImage}
      bgPosition={["contain", "contain", "center", "center"]}
      bgRepeat={"no-repeat"}
      justify="center"
      align={"center"}
      overflow={"hidden"}
    >
      <Image src={loginIcon} alt="icon" />
      {children}
    </Flex>
  );
};

export default AuthFlowWrapper;
