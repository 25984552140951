import { Stack } from "@chakra-ui/react";

function BoxShadowContainer({ children, p,  ...props}) {
  return (
    <Stack
      bg="#fff"
      // p={"20px 20px"}
      p={ p || "20px" }
      borderRadius={"6px"}
      mt="30px"
      boxShadow={"2px 2px 3px #0000000a"}
      width="100%"
      {...props}
      boxSizing="border-box !important"
    >
      {children}
    </Stack>
  );
}

export default BoxShadowContainer;
